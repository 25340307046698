import {
  roundBaseFields as roundBaseFieldsToken,
  betBaseFields as betBaseFieldsToken,
  userBaseFields as userBaseFieldsToken,
} from './tokenQueries'

export const getRoundBaseFields = (_tokenSymbol: string) => roundBaseFieldsToken

export const getBetBaseFields = (_tokenSymbol: string) => betBaseFieldsToken

export const getUserBaseFields = (_tokenSymbol: string) => userBaseFieldsToken
