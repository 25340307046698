import { Bet, PredictionUser } from 'state/types'
import { transformRoundResponseToken, transformUserResponseToken, transformBetResponseToken } from './tokenTransformers'

export const transformBetResponseEToken = (betResponse): Bet => {
  const baseBet = transformBetResponseToken(betResponse)
  const bet = {
    ...baseBet,
    claimedToken: betResponse.claimedToken ? parseFloat(betResponse.claimedToken) : 0,
    claimedNetToken: betResponse.claimedNetToken ? parseFloat(betResponse.claimedNetToken) : 0,
  } as Bet

  if (betResponse.user) {
    bet.user = transformUserResponseToken(betResponse.user)
  }

  if (betResponse.round) {
    bet.round = transformRoundResponseToken(betResponse.round, transformBetResponseToken)
  }

  return bet
}

export const transformUserResponseEToken = (userResponse): PredictionUser => {
  const baseUserResponse = transformUserResponseToken(userResponse)
  const { totalToken, totalTokenBull, totalTokenBear, totalTokenClaimed, averageToken, netToken } = userResponse || {}

  return {
    ...baseUserResponse,
    totalBNB: totalToken ? parseFloat(totalToken) : 0,
    totalBNBBull: totalTokenBull ? parseFloat(totalTokenBull) : 0,
    totalBNBBear: totalTokenBear ? parseFloat(totalTokenBear) : 0,
    totalBNBClaimed: totalTokenClaimed ? parseFloat(totalTokenClaimed) : 0,
    averageBNB: averageToken ? parseFloat(averageToken) : 0,
    netBNB: netToken ? parseFloat(netToken) : 0,
  }
}
