import { UserResponse, BetResponse, RoundResponse } from './responseType'

export interface UserResponseToken extends UserResponse<BetResponseToken> {
  totalToken: string
  totalTokenBull: string
  totalTokenBear: string
  averageToken: string
  totalTokenClaimed: string
  netToken: string
}

export interface BetResponseToken extends BetResponse {
  claimedToken: string
  claimedNetToken: string
  user?: UserResponseToken
  round?: RoundResponseToken
}

export type RoundResponseToken = RoundResponse<BetResponseToken>

/**
 * Base fields are the all the top-level fields available in the api. Used in multiple queries
 */
export const roundBaseFields = `
  id
  epoch
  position
  failed
  startAt
  startBlock
  startHash
  lockAt
  lockBlock
  lockHash
  lockPrice
  lockRoundId
  closeAt
  closeBlock
  closeHash
  closePrice
  closeRoundId
  totalBets
  totalAmount
  bullBets
  bullAmount
  bearBets
  bearAmount
`

export const betBaseFields = `
 id
 hash  
 amount
 position
 claimed
 claimedAt
 claimedHash
 claimedBlock
 claimedToken
 claimedNetToken
 createdAt
 updatedAt
`

export const userBaseFields = `
  id
  createdAt
  updatedAt
  block
  totalBets
  totalBetsBull
  totalBetsBear
  totalToken
  totalTokenBull
  totalTokenBear
  totalBetsClaimed
  totalTokenClaimed
  winRate
  averageToken
  netToken
`
